import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageBagage from "images/bagageCabine.png";
import imageSoute from "images/bagageSoute.png";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Politique bagage
          </h1>
          <span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          Tassili Airlines est fière de vous dévoiler sa nouvelle politique bagage, conçue pour offrir à nos clients l'expérience la plus avantageuse et la plus flexible du marché, à compter du 18 mai 2024. 
          </span>
          <span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          Fruit d'une écoute attentive des attentes de nos clients finaux, cette nouvelle politique bagage répond aux besoins exprimés par les voyageurs d'aujourd'hui. 
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >


<h3>Plus de choix, plus de satisfaction pour vos clients :</h3>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"><b>•	Franchise bagage diversifiée :</b> Nous proposons désormais une variété de franchises bagage sur nos vols vers Paris, Strasbourg et Nantes, afin de répondre aux besoins précis de chaque client.</span>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-4"><b>•	Tarifs optimisés :</b> Nos nouveaux tarifs bagages sont plus avantageux que jamais, permettant à nos clients de voyager sereinement et sans se soucier des frais supplémentaires.</span>

<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-4">Avec cette nouvelle politique bagage et le renforcement de nos dessertes, Tassili Airlines s'affirme comme le choix idéal pour nos clients :</span>

<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"><b>•	Plus de valeur pour leur argent :</b> Profitez de nos tarifs compétitifs et d'une franchise bagage généreuse pour offrir à nos clients un excellent rapport qualité-prix.</span>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"><b>•	Un voyage fluide et sans stress :</b> Dites adieu aux bagages hors format et aux frais inattendus. Nos nouvelles règles claires et simples permettent à nos clients de voyager en toute sérénité.</span>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"><b>•	Une expérience client exceptionnelle :</b> Offrez à nos clients un service de qualité supérieure et contribuez à leur satisfaction en leur proposant la meilleure politique bagage du marché. </span>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-4"><b>•	Plus de flexibilité :</b> Grâce à nos dessertes renforcées, nos clients peuvent désormais voyager vers plus de destinations et à des horaires plus variés.</span>

<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">Pour découvrir en détail tous les avantages de notre nouvelle politique bagage sur nos dessertes renforcées, nous vous invitons à consulter la présente note ou à contacter votre responsable commercial.</span>



<h3>1.	Bagage Cabine :</h3>

<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imageBagage}
      />

<h3>2.	2.	Bagage en soute :</h3>

<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imageSoute}
      />




<h3>3.	Excédent de bagage : </h3>
<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1"><b>Nouvelle tarification par palier :</b> En remplacement d'une tarification unitaire par kilo, nous proposons désormais une tarification par palier pour l'achat d'un bagage supplémentaire et le paiement des frais d'excédent de bagage. Cette nouvelle approche permet à nos clients de choisir l'option la plus adaptée à leurs besoins et à leur budget, en fonction du poids de leur bagage et de leur classe de réservation.</span>





     <ul>


<li className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
<div className="mt-6 border-t border-gray-100">
<dl className="divide-y divide-gray-100">
<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
<dt className="text-sm font-medium leading-6 text-gray-900 ">De 01 Kg à 7 Kg</dt>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 25 € </dd>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 4 000 DZD</dd>
</div>

<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
<dt className="text-sm font-medium leading-6 text-gray-900 ">De 08 Kg à 15 Kg</dt>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 50 €</dd>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 7 500 DZD</dd>
</div>

<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
<dt className="text-sm font-medium leading-6 text-gray-900 ">De 16 Kg à 23 Kg</dt>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 80 €</dd>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 12 000 DZD</dd>
</div>

<div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
<dt className="text-sm font-medium leading-6 text-gray-900 ">De 24 Kg à 30 Kg</dt>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 100 €</dd>
<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-5"> 15 000 DAD</dd>
</div>

</dl>
</div>
</li>
</ul>

<span className="block text-justify text-base text-red-500 md:text-lg dark:text-neutral-400 pb-1">Les passagers voyageant en classe tarifaire ECO LIGHT (sans bagage inclus) ont la possibilité d'ajouter des bagages en soute moyennant des frais :</span>


<span className="block text-justify text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">Franchise bagage de 23 ou 30 kg maximum au choix (max de deux pièces)  <b>selon le tableau ci-dessus</b></span>


<span className="block text-justify text-base text-neutral-500 md:text-base dark:text-neutral-400 pb-1"><b>Au-delà de 30 Kg :</b> Application de la tarification par palier selon le tableau ci-dessus.</span>
<span className="block text-justify text-base text-neutral-500 md:text-base dark:text-neutral-400 pb-1">Nous attirons votre attention sur le fait que ces conditions spécifiques s'appliquent uniquement aux passagers ECO LIGHT.</span>


<blockquote>
          <p>
          <small className="text-red-500"><strong><u>NB :</u></strong> Toute pièce excédent 32 Kg ne sera pas acceptée à l’enregistrement.</small>
          </p>
        </blockquote>




<blockquote>
          <p>
          <small><small><strong><u>Attention :</u></strong> Consultez les mesures de sûreté concernant les bagages.</small></small>
          </p>
        </blockquote>





      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour le vol à la demande
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Tassili Airlines || Vols à la demande</title>
      </Helmet>
      <NcImage
        className="w-100 rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName="container my-10 sm:my-12 "
        src="https://api.tassiliairlines.dz.tl/images/bagage3.jpg"
      />
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/*renderTags()*/}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentForm()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;

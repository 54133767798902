import React, { FC,useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import axios from "axios";
import PropTypes from "prop-types";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import ReCaptcha from "@matt-block/react-recaptcha-v2";

export interface PageContactProps {
  className?: string;
}

type ObjetType = {
  id: number,
  title: string,
}

const objets = [
  { id: 1, title: 'Paiement CIB/DAHABIA' },
  { id: 2, title: 'Paiement IATA' },
  { id: 3, title: 'Á propos de ma réservation' },
  { id: 3, title: 'Mise à disposition' },
  { id: 4, title: 'Autre' },
  // More users...
]

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const info = [
  {
    title: "🏢 Siège Social",
    desc: "168 Rue Hassiba Ben Bouali Alger Algérie",
  },
  {
    title: "📨 EMAIL",
    desc: "reservation@tassiliairlines.com\nremboursement@tassiliairlines.com",
  },
  {
    title: "📞 TEL",
    desc: "021 737 800",
  },
];

const infoFrance = [
  {
    title: "🏬 Adresse",
    desc: "122, Avenue des Champs Elysées – 75008 Paris",
  },
  {
    title: "📨 EMAIL",
    desc: "tassili.france@aviareps.com",
  },
  {
    title: "📞 TEL",
    desc: "0820 90 12 13",
  },
];




const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [captchaIsOk, setcaptchaIsOk] = useState(false);
  const [recaptchaCode, setrecaptchaCode] = useState('');
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    objet: '',
    recaptchaCode:'',
  });


  const [query, setQuery] = useState('')
const [selectedObjet, setSelectedObjet] = useState<ObjetType>()

const filteredObjets =
  query === ''
    ? objets
    : objets.filter((objet) => {
        return objet.title.toLowerCase().includes(query.toLowerCase())
      })


/**
  * @function handleFormSubmit
  * @param e { obj } - form event
  * @return void
  */
const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setFormData({
    name: formData.name,
    email: formData.email,
    message: formData.message,
    objet: formData.objet,
    recaptchaCode:recaptchaCode,
  });

  axios({
    method: "post",
    url: `${"https://api.tassiliairlines.dz.tl/contactus.php"}`,
    headers: { "content-type": "application/json" },
    data: formData
  })
    .then(result => {  console.log("sent:"+JSON.stringify(result));
      if (result.data===1) { 

        setFormData({
          name: '',
          email: '',
          message: '',
          objet: '',
          recaptchaCode:'',
        });

        setmailSent(true)
        setError(false)
      } else {
        setError(true)
      }
    })
    .catch(error => setError( error.message ));
};

const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  setQuery(event.target.value);
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};


function onChange(value:string) {
  console.log("Captcha value:", value);
}

function SetcaptchaIsOk(captchaCode:string,isOk:boolean)
{
  setrecaptchaCode(captchaCode);
  setcaptchaIsOk(isOk);
}
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact Tassili Airlines</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Contactez-nous
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">



            <div className="max-w-sm space-y-4">

            <div>                
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🇩🇿 Algérie
                </h3>
              </div>


              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}


<div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

              <div>                
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🇫🇷 France
                </h3>
              </div>
              {infoFrance.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}


            </div>


            <div>
              <form className="grid grid-cols-1 gap-6 form" method="post" data-form-title="Form Name" onSubmit={handleFormSubmit}>
                
              <label className="block">
<Combobox
      as="div"
      value={selectedObjet}
      onChange={(objet) => {
        setQuery('')
        setSelectedObjet(objet)
      }}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">L'objet du message</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleInputChange}
          onBlur={() => setQuery('')}
          displayValue={(objet:ObjetType) =>  objet?.title }
          name="objet" 
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredObjets.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredObjets.map((objet) => (
              <Combobox.Option
                key={objet.id}
                value={objet}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{objet.title}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>






                </label>

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>

                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                    data-form-field="name" 
                    name="name" value={formData.name} onChange={handleInputChange} 
                  />
                </label>
                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    data-form-field="email" 
                    className="mt-1"
                    name="email" value={formData.email} onChange={handleInputChange} 
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>

                  <Textarea className="mt-1" data-form-field="textarea" name="message" value={formData.message} onChange={handleInputChange} rows={6} />
                </label>

                <div>
                  {/*
  <GoogleReCaptchaProvider reCaptchaKey="6LfJQecpAAAAAFoOB94JNcYkJp4fgTwNAi6KotWo">
    <GoogleReCaptcha onVerify={onChange} />
  </GoogleReCaptchaProvider>
      */}
  <ReCaptcha
      siteKey="6LcwX-cpAAAAACDf_-tQVBZwVvF30WqIecDh6DPO"
      theme="light"
      size="normal"
      onSuccess={(captcha) => SetcaptchaIsOk(captcha,true) }
      onError={() => console.log("Something went wrong, check your conenction")}
      onExpire={() => console.log("Verification has expired, re-verify.")}
    />
  </div>

                <div>
                  <ButtonPrimary id="submitButton" 
                  disabled={(mailSent|| !captchaIsOk) ? true : false}
                  type="submit">Envoyez votre message</ButtonPrimary>
                </div>
                <div>
              {mailSent && <div className="sucsess">Votre message a été envoyé avec succès.</div>}
              {error && <div className="error">Il y a eu une erreur lors de l'envoie</div>}
            </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
 {/*       <div className="relative py-16">
          <BackgroundSection />
           <SectionClientSay uniqueClassName="Pagecontact_" />   
        </div>    */}
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
